@use 'vars' as *;
form {
  display: block;
  max-width: 403px;
}
input[type="text"], input[type="tel"],
input[type="email"], input[type="date"],
textarea {
  position: relative;
  display: block;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 26px;
  outline: none;
  box-shadow: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  resize: none;
  width: 100%;
  height: 50px;
  padding: 16px 0 6px;
  border: none;
  color: #3d3938;
  border-bottom: 1px solid $gray;
  background: none;
  border-radius: 0;
  transition: all 0.15s ease;
  @include font(18px, 22px);
  letter-spacing: 0.03em;
  transition: all .2s ease-out;
}
.is-invalid {
  display: block;
  position: relative;
  input {
    border-color: $red;
  }
  // span {
  //   display: block!important;
  //   position: absolute;
  //   height: 0;
  //   padding: 0;
  //   padding-left: 30px;
  //   bottom: 0;
  //   margin: -12px;
  //   @include font(12px, 14px);
  //   line-height: 0.035em;
  //   overflow: visible;
  //   color: inherit;
  // }
}
