@use './vars' as *;
.projects {
  position: relative;
  .slick-list {
    overflow: visible;
  }
  h2 {
    position: relative;
    display: inline-block;
    z-index: 1;
    margin-top: 0;
    @media (max-width: 992px) {
      margin-top: 20px;
    }
  }
  &-container {
    position: relative;
  }
  .slider-buttons {
    @include flex(row, nowrap, space-between, center);
    // margin: auto;
    position: absolute;
    left: 0;
    bottom: 40px;
    width: 120px;
  }
  &-slide img {
    margin: auto;
    margin-top: 0;
    filter: drop-shadow(0px 0px 80px rgba(25, 111, 239, 0.33));
  }
  .slick-arrow {
    cursor: pointer;
    position: relative;
    @include flex(row, nowrap, center, center);
    width: 43px;
    height: 43px;
    padding: 0;
    background-color: $white;
    // background-image: url('../img/svg/arrow-right.svg');
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
    transition: all .2s ease-out;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    svg path {
      transition: .3s all ease-out;
    }
    &:hover,
    &:focus {
      outline: none;
      background-color: $orange;
      box-shadow: 0px 16px 30px rgba(25, 111, 239, 0.24);
      svg path {
        fill: #ffffff;
      }
    }
    &:active {
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15),
                  0px 0px 10px rgba(0, 0, 0, 0.15);
    }
  }
  .slick-prev {
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}
@media (max-width: 992px) {
  .projects {
    background: none;
    &-backgrounds {
      display: none;
    }
    h2 {
      &:before {
        top: 18px;
        height: 22px;
      }
    }
    .projects-buttons {
      position: absolute;
      width: 100%;
      top: 210px;
      bottom: initial;
    }
    .slider-buttons {
      bottom: initial;
      width: 100%;
      height: 0;
      overflow: visible;
    }
  }
}
