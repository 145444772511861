@import url("https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap&subset=cyrillic");
html {
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  max-width: 1920px;
  min-height: 100vh;
  height: 100%;
  background: #DDF3FC;
  margin: 0 auto;
  padding: 0;
  padding-top: 0;
  font-size: 16px;
  line-height: 1.3;
  font-weight: normal;
  color: #373737;
  font-family: "Rubik", sans-serif;
  box-shadow: 0 0 10px #eeeeee;
}
body *,
body *:before,
body *:after {
  box-sizing: border-box;
}
body input, body button {
  -webkit-appearance: none;
}
body img {
  display: block;
  max-width: 100%;
  height: auto;
}
body a {
  text-decoration: none;
  color: #373737;
  font-weight: normal;
  transition: all 0.2s ease-out;
}
body a:hover, body a:focus {
  outline: none;
  color: #196FEF;
}

@media (max-width: 1900px) {
  body {
    max-width: 100%;
  }
}
.cancel-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1 {
  font-size: 56px;
  line-height: 52px;
  font-weight: 900;
  letter-spacing: 0.03em;
}

h2 {
  font-size: 48px;
  line-height: 1.2;
  font-weight: 900;
  letter-spacing: 0.03em;
}

h3 {
  color: #7c7c7c;
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  letter-spacing: 0.03em;
}

.list-item {
  position: relative;
  padding-left: 20px;
  font-size: 16px;
  line-height: 1.4;
  font-weight: normal;
  color: #373737;
  margin: 16px 0;
}
.list-item:before {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #f00000;
}

.btn {
  cursor: pointer;
  display: inline-block;
  min-width: 270px;
  padding: 21px 40px;
  border: none;
  border-radius: 20px;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  letter-spacing: 0.03em;
  transition: all 0.2s ease-out;
}
.btn:focus {
  outline: none;
}

.btn-default {
  position: relative;
  z-index: 1;
  color: #ffffff;
  background: linear-gradient(180deg, #196FEF 0%, #196FEF 100%);
}
.btn-default::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(180deg, #196FEF 0%, #22D9E4 100%);
  border-radius: 20px;
  z-index: -1;
  transition: opacity 0.2s linear;
  opacity: 0;
}
.btn-default:hover::before {
  opacity: 1;
}

.btn-close {
  display: block;
  max-width: 240px;
  margin: 0 auto;
  border: 2px solid #f00000;
  border-radius: 40px;
  font-weight: normal;
  height: 80px;
}
.btn-close:hover {
  color: #ffffff;
}

.btn-cancel {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  margin-top: 25px;
  padding: 0;
}

.basket-button {
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  bottom: 40px;
  right: 40px;
  width: 108px;
  height: 108px;
  border-radius: 50%;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.2);
}
.basket-button:hover {
  background: #548C1D;
}
.basket-button img {
  width: 50%;
  height: 50%;
  max-width: 50px;
  max-height: 50px;
}

.gray-span {
  display: block;
  color: #8D8D8D;
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
}

.thanks-page {
  text-align: center;
}
.thanks-page img {
  text-align: center;
  margin: auto;
}
.thanks-page a {
  display: inline-block;
  margin-top: 20px;
}

@media (max-width: 992px) {
  body {
    background: #DDF3FC;
  }

  h1, h2 {
    font-size: 30px;
    line-height: 1.23;
    font-weight: 900;
  }

  .basket-button {
    width: 65px;
    height: 65px;
    display: none;
  }

  .btn {
    display: block;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
  }

  .gray-span {
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
    margin-bottom: 315px;
  }
}
.app > section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 720px;
}

@media (max-width: 992px) {
  .app > section {
    height: auto;
    min-height: 0;
  }
}
.wrapper {
  width: 100%;
  max-width: 1250px;
  margin: auto;
  padding: 0;
  flex-shrink: 0;
}

.with-icon {
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
}

.list-icon {
  position: relative;
  display: inline-block;
  margin-right: 25px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  background: #f00000;
}
.list-icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 1250px) {
  .wrapper {
    padding: 0 14px;
  }
}
@media (max-width: 992px) {
  .wrapper {
    padding: 0;
  }

  .app > section {
    min-height: 0;
    height: 100% !important;
    padding-top: 50px;
  }

  .fp-tableCell {
    height: 100% !important;
  }
}
form {
  display: block;
  max-width: 403px;
}

input[type=text], input[type=tel],
input[type=email], input[type=date],
textarea {
  position: relative;
  display: block;
  font-family: "Rubik", sans-serif;
  margin-bottom: 26px;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  resize: none;
  width: 100%;
  height: 50px;
  padding: 16px 0 6px;
  border: none;
  color: #3d3938;
  border-bottom: 1px solid #7c7c7c;
  background: none;
  border-radius: 0;
  transition: all 0.15s ease;
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  letter-spacing: 0.03em;
  transition: all 0.2s ease-out;
}

.is-invalid {
  display: block;
  position: relative;
}
.is-invalid input {
  border-color: #f00000;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 9000;
  box-shadow: 0 15px 10px #DDF3FC;
  background-color: #DDF3FC;
  height: 70px;
}

.header-container {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 0;
  height: 70px;
  transform: translateX(-50%);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
}

.callback {
  text-align: right;
  letter-spacing: 0.03em;
}
.callback-number {
  display: block;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  margin-bottom: 5px;
}
.callback-link {
  display: block;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
}

.sidenav {
  position: fixed;
  top: 188px;
  left: 0;
  z-index: 9000;
  width: 65px;
}
.sidenav-list {
  width: 65px;
}
.sidenav-item {
  text-align: left;
  width: 100%;
}
.sidenav-link {
  position: relative;
  display: inline-block;
  padding: 12px 20px;
  padding-left: 32px;
  font-size: 12px;
  line-height: 1.3;
  font-weight: normal;
  color: #7c7c7c;
}
.sidenav-link::before {
  content: "";
  height: 1px;
  width: 4px;
  background: #7c7c7c;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 20px;
  transition: all 0.2s ease;
}
.sidenav-item.active .sidenav-link {
  border-radius: 40px;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 500;
  color: #373737;
}
.sidenav-item.active .sidenav-link::before {
  height: 2px;
  width: 10px;
  background: #373737;
  left: 14px;
}

@media (max-width: 992px) {
  .header-container {
    padding: 0 14px;
  }

  .sidenav {
    display: none;
  }
}
.calc-container {
  background: url("../img/calc_bg.png") no-repeat 0 0;
}
@media (max-width: 992px) {
  .calc-container {
    background: none;
  }
}
.calc-form .btn {
  display: block;
  margin: auto;
  margin-top: 50px;
}
.calc-label {
  position: relative;
  max-width: 425px;
  width: 100%;
  display: block;
}
.calc-caption {
  cursor: text;
  position: absolute;
  top: 25px;
  left: 0;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 400;
  width: 250px;
  color: #7c7c7c;
  transform-origin: 0 0;
  transition: all 0.2s ease;
}
.calc-border {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: #7c7c7c;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: all 0.15s ease;
}
.calc-label input:not(:placeholder-shown) + span {
  color: #7c7c7c;
  transform: translateY(-25px) scale(0.75);
}
.calc-label input:focus {
  background: none;
  outline: none;
}
.calc-label input:focus + span {
  color: #7c7c7c;
  transform: translateY(-25px) scale(0.75);
}
.calc-label input:focus + span + .callback-border {
  transform: scaleX(1);
  background: #f00000;
}

@media (max-width: 992px) {
  .calc h2::before {
    top: 18px;
    height: 22px;
    width: 205px;
  }
}
.clients {
  background: url("../img/doctor.png") no-repeat 14vw 90%;
}
@media (max-width: 1400px) {
  .clients {
    background: url("../img/doctor.png") no-repeat 1vw 90%;
  }
}
.clients-container {
  padding: 50px 0 20px;
  overflow: visible;
}
.clients-list {
  list-style: none;
  padding: 0;
  padding-top: 50px;
}
.clients-list > div {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 25px;
}
.clients-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 140px;
  text-align: center;
  background-color: #E2F7FF;
  border-radius: 14px;
}
.clients-item img {
  mix-blend-mode: darken;
}

@media (max-width: 992px) {
  .clients {
    background: none;
  }
  .clients h2::before {
    top: 18px;
    left: 30%;
    width: 128px;
    height: 22px;
  }
  .clients-list {
    margin: 0;
  }
}
.main {
  background: url("../img/main_bg.png") no-repeat 84% 80px;
}
.main h1 {
  margin-top: 10px;
}
.main h3 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 0;
}
.main-container {
  padding-top: 50px;
  padding-bottom: 300px;
}
.main-header {
  padding-top: 200px;
  position: relative;
}
.main-list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  background: #DDF3FC;
}
.main-item {
  padding: 0px 6px;
}
.main-item img {
  mix-blend-mode: darken;
  max-width: 105px;
}
.main-list-link {
  position: relative;
  display: block;
  padding-left: 50px;
}
.main-list-link:before {
  content: "";
  position: absolute;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  background-image: url("../img/arrow_right.png");
  background-position: center center;
  border-radius: 50%;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease-out;
}
.main-list-link:hover:before, .main-list-link:focus:before {
  background-color: #196FEF;
}
.main-list-link:active:before {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15), inset 0px 0px 10px rgba(0, 0, 0, 0.15);
}

@media (max-width: 992px) {
  .main {
    background: #DDF3FC;
  }
  .main .wrapper {
    background: url("../img/main_bg.png") no-repeat 50% 60%;
    background-size: 400px;
  }
  .main-container {
    background: none;
  }
  .main-header {
    padding-top: 0;
  }
  .main-header span {
    left: 150px;
  }
  .main-item:nth-child(3) {
    display: none;
  }
  .main-item span {
    display: none;
  }
}
@media (max-width: 768px) {
  .main-item:nth-child(4) {
    display: none;
  }
}
@media (max-width: 520px) {
  .main-item:nth-child(5) {
    display: none;
  }
}
.contacts-map iframe {
  border-radius: 27px;
  border: none;
}
.contacts-list {
  padding-top: 20px;
}
.contacts-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 10px;
}
.contacts-header {
  color: #7c7c7c;
}
.contacts-text {
  margin: 5px 0;
  font-size: 22px;
  line-height: 1.3;
  font-weight: 500;
}

@media (max-width: 992px) {
  .contacts h2:before {
    top: 18px;
    left: 15px;
    height: 22px;
  }

  .map {
    order: 2;
  }

  .contacts-info {
    order: 1;
  }
}
.features {
  background: url("../img/features_img.png") no-repeat 100% 100%;
}
.features h2 {
  position: relative;
  z-index: 1;
}
.features-list {
  padding-top: 50px;
  margin: 0 -15px;
}
.features-item {
  position: relative;
  margin-bottom: 25px;
  padding-left: 60px;
  color: #7c7c7c;
}
.features-item::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #2E72CA;
}
.features-item::after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 28px;
  height: 28px;
  left: 1px;
  border-radius: 50%;
  background: #ffffff url("../img/svg/okay.svg") no-repeat center center;
}

@media (max-width: 992px) {
  .features {
    background: none;
  }
  .features h2::before {
    top: unset;
    bottom: -3px;
    left: -1%;
    width: 205px;
    height: 22px;
  }
  .features-container {
    background: none;
  }
}
.freelance h2 {
  position: relative;
}

.freelance-container {
  background: url("../img/freelance_image.png") no-repeat 70px 100%;
}

.question,
.answer {
  margin-bottom: 40px;
  padding: 24px 24px;
  border-radius: 18px;
}
.question p,
.answer p {
  margin: 0;
}

.question {
  position: relative;
  background-color: #196FEF;
  color: #ffffff;
}
.question-circle {
  position: absolute;
  top: -15px;
  right: -15px;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #196fef;
}
.question:before {
  content: "";
  position: absolute;
  top: -65px;
  right: -82px;
  width: 42px;
  height: 42px;
  background: url("../img/emoji.png") no-repeat 0 0;
}
.question:after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: -4px;
  background: url("../img/pointer.svg") no-repeat 0 0;
  width: 12px;
  height: 18px;
}

.answer {
  background-color: #F0F5FB;
}
.answer:after {
  content: "";
  position: absolute;
  bottom: -3px;
  right: -4px;
  background: url("../img/pointer_2.svg") no-repeat 0 0;
  width: 12px;
  height: 18px;
}

@media (max-width: 992px) {
  .question::before {
    display: none;
  }
  .question-circle {
    display: none;
  }

  .question,
.answer {
    margin-bottom: 20px;
  }

  .freelance h2 {
    position: relative;
  }
  .freelance h2::before {
    top: 19px;
    left: -6px;
    width: 82px;
    height: 22px;
    background-color: #196FEF;
    border-radius: 40px;
  }
}
.hero h2 {
  position: relative;
}
.hero-list {
  padding-top: 50px;
}
.hero-item {
  margin-bottom: 40px;
  text-align: center;
}
.hero-icon {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  margin: auto;
  margin-bottom: 40px;
  border-radius: 50%;
  background: #C4EBF9;
}
.hero-header {
  display: block;
  font-weight: 500;
  margin: auto;
  margin-bottom: 20px;
  max-width: 185px;
}
.hero-text {
  display: block;
  margin: auto;
  color: #7c7c7c;
}

@media (max-width: 992px) {
  .hero h2 {
    position: relative;
  }
  .hero h2::before {
    z-index: -1;
    top: 50px;
    left: 49%;
    width: 148px;
    height: 22px;
  }
}
.projects {
  position: relative;
}
.projects .slick-list {
  overflow: visible;
}
.projects h2 {
  position: relative;
  display: inline-block;
  z-index: 1;
  margin-top: 0;
}
@media (max-width: 992px) {
  .projects h2 {
    margin-top: 20px;
  }
}
.projects-container {
  position: relative;
}
.projects .slider-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 40px;
  width: 120px;
}
.projects-slide img {
  margin: auto;
  margin-top: 0;
  filter: drop-shadow(0px 0px 80px rgba(25, 111, 239, 0.33));
}
.projects .slick-arrow {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  padding: 0;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  transition: all 0.2s ease-out;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.projects .slick-arrow svg path {
  transition: 0.3s all ease-out;
}
.projects .slick-arrow:hover, .projects .slick-arrow:focus {
  outline: none;
  background-color: #196FEF;
  box-shadow: 0px 16px 30px rgba(25, 111, 239, 0.24);
}
.projects .slick-arrow:hover svg path, .projects .slick-arrow:focus svg path {
  fill: #ffffff;
}
.projects .slick-arrow:active {
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15), 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.projects .slick-prev {
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

@media (max-width: 992px) {
  .projects {
    background: none;
  }
  .projects-backgrounds {
    display: none;
  }
  .projects h2:before {
    top: 18px;
    height: 22px;
  }
  .projects .projects-buttons {
    position: absolute;
    width: 100%;
    top: 210px;
    bottom: initial;
  }
  .projects .slider-buttons {
    bottom: initial;
    width: 100%;
    height: 0;
    overflow: visible;
  }
}
.solution h2 {
  position: relative;
}
.solution-col {
  margin-top: 150px;
  background-color: #EFFAFF;
  padding: 40px;
  border-radius: 46px;
}
.solution-col:before {
  content: "";
  position: absolute;
  top: -130px;
  right: -90px;
  width: 285px;
  height: 274px;
  background: url("../img/solution_image.png") 0 0/contain no-repeat;
}
.solution-col .list-item::before {
  background-color: #196FEF;
}
.solution-callback {
  margin-top: 30px;
}

@media (max-width: 992px) {
  .solution h2::before {
    top: 17px;
    width: 148px;
    height: 22px;
  }
  .solution-col {
    margin-left: 14px;
    margin-right: 14px;
  }
}
.thanks-container {
  height: 100%;
  padding-top: 200px;
}
@media (max-width: 992px) {
  .thanks-container {
    padding-top: 50px;
  }
}
.thanks-container p {
  color: #7c7c7c;
  font-size: 16px;
  line-height: 1.7;
  font-weight: normal;
}
.thanks h2 {
  position: relative;
}
.thanks-ceo img {
  display: block;
}

.open-code {
  margin-top: 40px;
}

@media (max-width: 992px) {
  .thanks-container {
    background: none;
  }
  .thanks h2::before {
    top: 18px;
    left: -1%;
    width: 129px;
    height: 22px;
  }
  .thanks-ceo {
    order: 2;
  }
  .thanks-ceo img {
    display: block;
  }
  .thanks-text {
    order: 1;
  }
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 9200;
  width: 100%;
  display: none;
  max-width: 744px;
  padding: 40px 40px 60px;
  background: #ffffff;
  border-radius: 40px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.2);
}
.modal .btn {
  display: block;
  margin: auto;
  margin-top: 50px;
}
.modal .btn-close {
  padding: 27px 30px;
}
.modal form {
  max-width: 400px;
  width: 100%;
  margin: 40px auto;
}
.modal-overlay {
  display: none;
  position: fixed;
  z-index: 9100;
  backdrop-filter: blur(3px);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
}

.modal-close {
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: 40px;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
}

.modal-header {
  display: block;
  margin-bottom: 12px;
  text-align: center;
}

.modal-hint {
  display: block;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 23px;
  font-weight: normal;
  letter-spacing: 0.03em;
  color: #7c7c7c;
}

.modal-hint-code {
  display: block;
  text-align: center;
  font-size: 24px;
  line-height: 1.3;
  font-weight: normal;
  letter-spacing: 0.03em;
  color: #7c7c7c;
}

.modal-thanks img {
  margin: 20px auto;
}