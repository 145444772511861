@use './vars' as *;
.contacts {
  &-map iframe {
    border-radius: 27px;
    border: none;
  }
  &-list {
    padding-top: 20px;
  }
  &-item {
    @include flex(column);
    margin-bottom: 10px;
  }
  &-header {
    color: $gray;
  }
  &-text {
    margin: 5px 0;
    @include font(22px, 1.3, 500);
  }
}
@media (max-width: 992px) {
  .contacts {
    h2 {
      &:before {
        top: 18px;
        left: 15px;
        height: 22px;
      }
    }
  }
  .map {
    order: 2;
  }
  .contacts-info {
    order: 1;
  }
}
