@use './vars' as *;
.solution {
  h2 {
    position: relative;
  }
  &-col {
    margin-top: 150px;
    background-color: #EFFAFF;
    padding: 40px;
    border-radius: 46px;
    &:before {
      content: '';
      position: absolute;
      top: -130px;
      right: -90px;
      width: 285px;
      height: 274px;
      background: url('../img/solution_image.png') 0 0/contain no-repeat;
    }
    .list-item::before {
      background-color: $orange;
    }
}
  &-callback {
    margin-top: 30px;
  }
}
@media (max-width: 992px) {
  .solution {
    h2 {
      &::before {
        top: 17px;
        width: 148px;
        height: 22px;
      }
    }
    &-col {
      margin-left: 14px;
      margin-right: 14px;
    }
  }
}
