@use './vars' as *;
.thanks {
  &-container {
    // background: url('../img/anton.png') no-repeat 1vw 100px;
    height: 100%;
    padding-top: 200px;
    @media (max-width: 992px) {
      padding-top: 50px;
    }
    p {
      color: $gray;
      @include font(16px, 1.7);
    }
  }
  h2 {
    position: relative;
  }
  &-ceo img {
    display: block;
  }
}
.open-code {
  margin-top: 40px;
}
@media (max-width: 992px) {
  .thanks {
    &-container {
      background: none;
    }
    h2 {
      &::before {
        top: 18px;
        left: -1%;
        width: 129px;
        height: 22px;
      }
    }
    &-ceo {
      order: 2;
      img {
        display: block;
      }
    }
    &-text {
      order: 1;
    }
  }
}
