@use './vars' as *;
.freelance {
  h2 {
    position: relative;
  }
}
.freelance-container {
  background: url('../img/freelance_image.png') no-repeat 70px 100%;
}
.question,
.answer {
  margin-bottom: 40px;
  padding: 24px 24px;
  border-radius: 18px;
  & p {
    margin: 0;
  }
}
.question {
  position: relative;
  background-color: #196FEF;
  color: $white;
  &-circle {
    position: absolute;
    top: -15px;
    right: -15px;
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #196fef;
  }
  &:before {
    content: '';
    position: absolute;
    top: -65px;
    right: -82px;
    width: 42px;
    height: 42px;
    background: url('../img/emoji.png') no-repeat 0 0;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: -4px;
    background: url('../img/pointer.svg') no-repeat 0 0;
    width: 12px;
    height: 18px;
  }
}
.answer {
  background-color: #F0F5FB;
  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    right: -4px;
    background: url('../img/pointer_2.svg') no-repeat 0 0;
    width: 12px;
    height: 18px;
  }
}
@media (max-width: 992px) {
  .question {
    &::before {
      display: none;
    }
    &-circle {
      display: none;
    }
  }
  .question,
  .answer {
    margin-bottom: 20px;
  }
  .freelance {
    h2 {
      position: relative;
      &::before {
        top: 19px;
        left: -6px;
        width: 82px;
        height: 22px;
        background-color: $orange;
        border-radius: 40px;
      }
    }
  }
}
