@use '../vars' as *;
.features {
  background: url('../img/features_img.png') no-repeat 100% 100%;
  h2 {
    position: relative;
    z-index: 1;
  }
  &-list {
    padding-top: 50px;
    margin: 0 -15px;
  }
  &-item {
    position: relative;
    margin-bottom: 25px;
    padding-left: 60px;
    color: $gray;
    &::before {
      content: '';
      @include center('vertical');
      left: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #2E72CA;
    }
    &::after {
      content: '';
      @include center('vertical');
      width: 28px;
      height: 28px;
      left: 1px;
      border-radius: 50%;
      background: $white url('../img/svg/okay.svg') no-repeat center center;
    }
  }
}
@media (max-width: 992px) {
  .features {
    h2 {
      &::before {
        top: unset;
        bottom: -3px;
        left: -1%;
        width: 205px;
        height: 22px;
      }
    }
    background: none;
    &-container {
      background: none;
    }
  }
}
