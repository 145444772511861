@use './vars' as *;
.hero {
  h2 {
    position: relative;
  }
  &-list {
    padding-top: 50px;
    // @include flex(row, nowrap, space-between, flex-start);
  }
  &-item {
    margin-bottom: 40px;
    text-align: center;
    // max-width: 190px;
  }
  &-icon {
    @include flex(row, nowrap, center, center);
    width: 68px;
    height: 68px;
    margin: auto;
    margin-bottom: 40px;
    border-radius: 50%;
    background: #C4EBF9;
  }
  &-header {
    display: block;
    font-weight: 500;
    margin: auto;
    margin-bottom: 20px;
    max-width: 185px;
  }
  &-text {
    display: block;
    margin: auto;
    color: $gray;
  }
}
@media (max-width: 992px) {
  .hero {
    h2 {
      position: relative;
      &::before {
        z-index: -1;
        top: 50px;
        left: 49%;
        width: 148px;
        height: 22px;
      }
    }
  }
}
