@use '../vars' as *;
.header {
  position: fixed;
  width: 100%;
  z-index: 9000;
  box-shadow: 0 15px 10px #DDF3FC;
  background-color: #DDF3FC;
  height: 70px;
}
.header-container {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 0;
  height: 70px;
  transform: translateX(-50%);
  @include flex(row, nowrap, space-between, flex-end);
}
.callback {
  text-align: right;
  letter-spacing: 0.03em;
  &-number {
    display: block;
    @include font(16px, 19px, 500);
    margin-bottom: 5px;
  }
  &-link {
    display: block;
    @include font(12px, 14px, normal);
  }
}
.sidenav {
  // @include center('horizontal');
  position: fixed;
  top: 188px;
  left: 0;
  z-index: 9000;
  width: 65px;
  &-list {
    width: 65px;
  }
  &-item {
    text-align: left;
    width: 100%;
  }
  &-link {
    position: relative;
    display: inline-block;
    padding: 12px 20px;
    padding-left: 32px;
    @include font(12px, 1.3, normal);
    color: $gray;
    &::before {
      content: '';
      height: 1px;
      width: 4px;
      background: $gray;
      @include center('vertical');
      left: 20px;
      transition: all 0.2s ease;
    }
  }
  &-item.active &-link {
    border-radius: 40px;
    @include font(14px, 1.3, 500);
    color: $black;
    &::before {
      height: 2px;
      width: 10px;
      background: $black;
      left: 14px;
    }
  }
}
@media (max-width: 992px) {
  .header-container {
    padding: 0 14px;
  }
  .sidenav {
    display: none;
  }
}
