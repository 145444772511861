@use 'vars' as *;
.calc {
  &-container {
    background: url('../img/calc_bg.png') no-repeat 0 0;
    @media (max-width: 992px) {
      background: none;
    }
  }
  &-form .btn {
    display: block;
    margin: auto;
    margin-top: 50px;
  }
  &-label {
    position: relative;
    max-width: 425px;
    width: 100%;
    display: block;
  }
  &-caption {
    cursor: text;
    position: absolute;
    top: 25px;
    left: 0;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 400;
    width: 250px;
    color: $gray;
    transform-origin: 0 0;
    transition: all 0.2s ease;
  }
  &-border {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: $gray;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all 0.15s ease;
  }
  &-label input:not(:placeholder-shown) + span {
    color: $gray;
    transform: translateY(-25px) scale(0.75);
  }
  &-label input:focus {
    background: none;
    outline: none;
  }
  &-label input:focus + span {
    color: $gray;
    transform: translateY(-25px) scale(0.75);
  }
  &-label input:focus + span + .callback-border {
    transform: scaleX(1);
    background: $red;
  }
}
@media (max-width: 992px) {
  .calc {
    h2 {
      &::before {
        top: 18px;
        height: 22px;
        width: 205px;
      }
    }
  }
}
