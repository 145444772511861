@use './vars' as *;
html {
  margin: 0;
  padding: 0;
}
body {
  position: relative;
  max-width: 1920px;
  min-height: 100vh;
  height: 100%;
  background: #DDF3FC;
  // @include flex(column, nowrap, space-between, auto);
  margin: 0 auto;
  padding: 0;
  padding-top: 0;
  @include font(16px, 1.3, normal);
  color: $black;
  font-family: 'Rubik', sans-serif;
  box-shadow: 0 0 10px #eeeeee;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  input, button {
    -webkit-appearance: none;
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  a {
    text-decoration: none;
    color: $black;
    font-weight: normal;
    transition: all .2s ease-out;
    &:hover,
    &:focus {
      outline: none;
      color: $orange;
    }
  }
}
@media (max-width: 1900px) {
  body {
    max-width: 100%;
  }
}
.cancel-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1 {
  @include font(56px, 52px, 900);
  letter-spacing: 0.03em;
}
h2 {
  @include font(48px, 1.2, 900);
  letter-spacing: 0.03em;
}
h3 {
  color: $gray;
  @include font(18px, 21px, 500);
  letter-spacing: 0.03em;
}
.list-item {
  position: relative;
  padding-left: 20px;
  @include font(16px, 1.4, normal);
  color: $black;
  margin: 16px 0;
  &:before {
    content: '';
    // @include center('vertical');
    position: absolute;
    top: 9px;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $red;
  }
}
.btn {
  cursor: pointer;
  display: inline-block;
  min-width: 270px;
  padding: 21px 40px;
  border: none;
  border-radius: 20px;
  text-align: center;
  font-family: 'Rubik', sans-serif;
  @include font(16px, 19px, bold);
  letter-spacing: 0.03em;
  transition: all 0.2s ease-out;
  &:focus {
    outline: none;
  }
}
.btn-default {
  position: relative;
  z-index: 1;
  color: $white;
  background: linear-gradient(180deg, $orange 0%, $orange 100%);
  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(180deg, #196FEF 0%, #22D9E4 100%);
    border-radius: 20px;
    z-index: -1;
    transition: opacity .2s linear;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }
}
.btn-close {
  display: block;
  max-width: 240px;
  margin: 0 auto;
  border: 2px solid $red;
  border-radius: 40px;
  font-weight: normal;
  height: 80px;
  &:hover {
    color: #ffffff;
  }
}
.btn-cancel {
  @include font(16px, 19px, 500);
  margin-top: 25px;
  padding: 0;
}
.basket-button {
  position: fixed;
  @include flex(row, nowrap, center, center);
  bottom: 40px;
  right: 40px;
  width: 108px;
  height: 108px;
  border-radius: 50%;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.2);
  &:hover {
    background: #548C1D;
  }
  img {
    width: 50%;
    height: 50%;
    max-width: 50px;
    max-height: 50px
  }
}
.gray-span {
  display: block;
  color: #8D8D8D;
  @include font(22px, 26px, 700);
}
.thanks-page {
  text-align: center;
  img {
    text-align: center;
    margin: auto;
  }
  a {
    display: inline-block;
    margin-top: 20px;
  }
}
@media (max-width: 992px) {
  body {
    background: #DDF3FC;
  }
  h1, h2 {
    @include font(30px, 1.23, 900);
  }
  .basket-button {
    width: 65px;
    height: 65px;
    display: none;
  }
  .btn {
    display: block;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
  }
  .gray-span {
    @include font(16px, 19px);
    margin-bottom: 315px;
  }
}
