@use 'vars' as *;
.app > section {
  @include flex(row, nowrap, flex-start, center);
  // height: 100vh;
  min-height: 720px;
  // outline: 1px solid $gray;
}
@media (max-width: 992px) {
  .app > section {
    height: auto;
    min-height: 0;
  }
}
.wrapper {
  width: 100%;
  max-width: 1250px;
  margin: auto;
  padding: 0;
  flex-shrink: 0;
}
.with-icon {
  @include font(16px, 19px);
}
.list-icon {
  position: relative;
  display: inline-block;
  margin-right: 25px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  background: $red;
  img {
    @include center('both');
  }
}
@media (max-width: 1250px) {
  .wrapper {
    padding: 0 14px;
  }
}
@media (max-width: 992px) {
  .wrapper {
    padding: 0;
  }
  .app > section {
    min-height: 0;
    height: 100% !important;
    padding-top: 50px;
  }
  .fp-tableCell {
    height: 100% !important;
  }
}
