@use '../vars' as *;
.clients {
  background: url('../img/doctor.png') no-repeat 14vw 90%;
  @media (max-width: 1400px) {
    background: url('../img/doctor.png') no-repeat 1vw 90%;
  }
  &-container {
    padding: 50px 0 20px;
    overflow: visible;
  }
  &-list {
    list-style: none;
    padding: 0;
    padding-top: 50px;
    & > div {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 25px;
    }
  }
  &-item {
    @include flex(row, nowrap, center, center);
    height: 140px;
    // padding: 45px 0;
    text-align: center;
    background-color: #E2F7FF;
    border-radius: 14px;
    img {
      mix-blend-mode: darken;
    }
  }
}
@media (max-width: 992px) {
  .clients {
    background: none;
    h2 {
      &::before {
        top: 18px;
        left: 30%;
        width: 128px;
        height: 22px;
      }
    }
    &-list {
      margin: 0;
    }
  }
}
