@use 'vars' as *;
.modal {
  @include center('both');
  position: fixed;
  z-index: 9200;
  width: 100%;
  display: none;
  max-width: 744px;
  padding: 40px 40px 60px;
  background: #ffffff;
  border-radius: 40px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.2);
  .btn {
    display: block;
    margin: auto;
    margin-top: 50px;
  }
  .btn-close {
    padding: 27px 30px;
  }
  form {
    max-width: 400px;
    width: 100%;
    margin: 40px auto;
  }
  &-overlay {
    display: none;
    position: fixed;
    z-index: 9100;
    backdrop-filter: blur(3px);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
  }
}
.modal-close {
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: 40px;
  @include font(16px, 19px);
}
.modal-header {
  display: block;
  margin-bottom: 12px;
  text-align: center;
}
.modal-hint {
  display: block;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  @include font(18px, 23px);
  letter-spacing: 0.03em;
  color: $gray;
}
.modal-hint-code {
  display: block;
  text-align: center;
  @include font(24px, 1.3);
  letter-spacing: 0.03em;
  color: $gray;
}
.modal-thanks img {
  margin: 20px auto;
}
