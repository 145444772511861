@use 'vars' as *;
.main {
  background: url('../img/main_bg.png') no-repeat 84% 80px;
  h1 {
    margin-top: 10px;
  }
  h3 {
    @include font(24px, 28px, 500);
    margin-bottom: 0;
  }
  &-container {
    padding-top: 50px;
    padding-bottom: 300px;
  }
  &-header {
    padding-top: 200px;
    position: relative;
  }
  &-list {
    @include flex(row, nowrap, space-between, center);
    height: 90px;
    background: #DDF3FC;
  }
  &-item {
    padding: 0px 6px;
    img {
      mix-blend-mode: darken;
      max-width: 105px;
    }
  }
  &-list-link {
    position: relative;
    display: block;
    padding-left: 50px;
    &:before {
      content: '';
      position: absolute;
      @include center('vertical');
      left: 0;
      width: 25px;
      height: 25px;
      background-color: $white;
      background-image: url('../img/arrow_right.png');
      background-position: center center;
      border-radius: 50%;
      background-repeat: no-repeat;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      transition: all .2s ease-out;
    }
    &:hover:before,
    &:focus:before {
      background-color: $orange
    }
    &:active:before {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15),
                  inset 0px 0px 10px rgba(0, 0, 0, 0.15);
    }
  }
}
@media (max-width: 992px) {
  .main {
    background: #DDF3FC;
    .wrapper {
      background: url('../img/main_bg.png') no-repeat 50% 60%;
      background-size: 400px;
    }
    &-container {
      background: none;
    }
    &-header {
      padding-top: 0;
      span {
        left: 150px;
      }
    }
    &-item:nth-child(3) {
      display: none;
    }
    &-item {
      span {
        display: none;
      }
    }
  }
}
@media (max-width: 768px) {
  .main {
    &-item:nth-child(4) {
      display: none;
    }
  }
}
@media (max-width: 520px) {
  .main {
    &-item:nth-child(5) {
      display: none;
    }
  }
}
